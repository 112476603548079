<template>
    <div class="page-body wrapper-fixed">

        <div class="login-control d-flex">
            <language-switcher class="ml-auto"></language-switcher>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background" />
        </div>
        <div class="col-md-6 login-content">
            <div class="text-center">
                <h4 class="page-title">{{ $t("Easiest Recruitment Solution - Attract, Manage & Hire Right Talent.")}}</h4>
                <p class="page-description">{{ $t("Advanced Recruiting Solution With Everything You Need To Accelerate The Hiring Process.")}}</p>
            </div>
            <div class="login-box">
                <h2 class="login-box__title text-capitalize">{{ $t("please sign up")}}</h2>
                <div class="signup-message text-center">{{ $t("Already have an account?")}}
                    <router-link :to="{name : 'login', query: $route.query}" class="color-primary">{{ $t("Sign In")}}</router-link>
                </div>
                <div class="social-signin">
                    <button class="social-signin__button" @click="AuthProvider('google')">
                        <div class="social-signin__button__logo">
                            <img src="/img/google-colorfull.svg" alt="" class="img-fluid" />
                        </div>
                        <p class="social-signin__button__text">{{ $t("Sign Up with Google")}}</p>
                    </button>
                    <button class="social-signin__button" @click="AuthProvider('linkedin')">
                        <div class="social-signin__button__logo">
                            <i class="eicon e-linkedin-cf"></i>
                        </div>
                        <p class="social-signin__button__text">{{ $t("Sign Up with LinkedIn")}}</p>
                    </button>
                </div>
                <alert></alert>
                <form class="login-form">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-capitalize">{{ $t("First name")}}</label>
                                <input class="form-control" v-bind:class="{ 'has-error': errors.first_name }" maxlength="50" type="text"
                                       v-model="form.first_name" :placeholder="$t('enter your first name')"/>
                                <error-message :message="$t(errors.first_name)" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="text-capitalize">{{ $t("Last name")}}</label>
                                <input class="form-control" v-bind:class="{ 'has-error': errors.last_name }" maxlength="50" type="text"
                                       v-model="form.last_name" :placeholder="$t('enter your last name')"/>
                                <error-message :message="$t(errors.last_name)" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="text-capitalize">{{ $t("email address")}}</label>
                        <input class="form-control" v-bind:class="{ 'has-error': errors.email }" maxlength="100" type="email"
                               v-model="form.email" placeholder="youremail@gmail.com"/>
                        <error-message :message="$t(errors.email)" />
                    </div>
                    <div class="form-group">
                        <label>{{ $t("Password")}}</label>
                        <input class="form-control" v-bind:class="{ 'has-error': errors.password }"
                               type="password" v-model="form.password" :placeholder="$t('Password')"/>
                        <error-message :message="$t(errors.password)" />

                    </div>

                    <div class="form-group">
                        <label>{{ $t("Confirm Password")}}</label>
                        <input class="form-control" v-bind:class="{ 'has-error': errors.password_confirmation }"
                               type="password" v-model="form.password_confirmation" :placeholder="$t('re-type password')"/>
                        <error-message :message="errors.password_confirmation" />

                    </div>

                    <submit-button :click="signUp" :block="true" :loading="isLoading">{{ $t("Sign Up")}}</submit-button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import SiteLogoSVG from "../components/SiteLogoSVG";
    const LanguageSwitcher = () => import("../components/dropdown/LanguageSwitcher.vue");
    import { mapGetters, mapActions } from 'vuex';
    import {SWITCH_TO_ACCOUNT, USER_LOGGED_IN} from "../constants/action-type";
    import client from "../app/api/Client";
    const ErrorMessage = () => import("../components/common/ErrorMessage.vue");
    import VueSocialAuth from 'vue-social-auth';
    import authConfig from '../config/auth';
    import {USER_TYPE} from "../constants/enums";
    Vue.use(VueSocialAuth, authConfig.socialAuth);

    export default {
        computed: {
            ...mapGetters(['getUser'])
        },
        components : {
            SiteLogoSVG,
            ErrorMessage,
            LanguageSwitcher,
        },
        data() {
            return {
                form: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    time_zone: "UTC"

                },
                errors: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",

                },
                isLoading: false,
                timeZone: "UTC"

            }
        },
        methods: {
            ...mapActions([USER_LOGGED_IN]),
            async signUp() {
                if (this.validateForm()) {
                    this.isLoading = true;
                    this.form.time_zone= this.timeZone;
                    try {
                        let {data : {data}} = await client().post('/sign-up', this.form);
                        this[USER_LOGGED_IN](data);

                        if (data.type === USER_TYPE.CANDIDATE) {
                            // go to candidate dashboard
                            return this.$router.push({name: 'dashboard'});
                        } else {
                            // go to subscribe page if not subscribed to any package
                            if (!data.package_id) {
                                return this.$router.push({name: 'subscribe', query: this.$route.query});
                            }

                            // go to company create page if no company
                            if (!data.companies.length) {
                                return this.$router.push({name: 'company.create'});
                            }
                            // go to current company if set
                            if (data.current_company) {
                                let company = _.find(data.companies, {id: data.current_company});
                                this[SWITCH_TO_ACCOUNT](company);
                                return this.$router.push({name: 'dashboard'});
                            }
                            // go to first company
                            if (data.companies.length) {
                                let company = _.first(data.companies);
                                this[SWITCH_TO_ACCOUNT](company);
                                return this.$router.push({name: 'dashboard'});
                            }

                            return this.$router.push({name: 'dashboard'});
                        }

                        let plan = this.$route.query.plan ?? 'free';
                        return await this.$router.push({name: 'subscribe', query: this.$route.query});
                    } catch (error) {
                        let errors = error.response.data.message;
                        if (typeof errors === 'object') {
                            this.errors = _helper.serializeValidationMessage(errors);
                        } else {
                            this.$toast.error(errors);
                        }
                    }
                    this.isLoading = false;
                }
            },
            AuthProvider(provider) {
                this.$auth.authenticate(provider).then(response =>{
                    this.SocialLogin(provider,response)
                }).catch(err => {
                    this.$toast.error("Cannot register with social.");
                })
            },
            SocialLogin(provider,response){
                client().post('/sign-in-oauth/'+provider,response).then(({data : {data}}) => {
                    this[USER_LOGGED_IN](data);
                    // this.$router.push({name: 'dashboard', query: this.$route.query});
                    // go to subscribe page if not subscribed to any package
                    if (!data.package_id) {
                        return this.$router.push({name: 'subscribe', query: this.$route.query});
                    }

                    // go to company create page if no company
                    if (!data.companies.length) {
                        return this.$router.push({name: 'company.create'});
                    }
                    // go to current company if set
                    if (data.current_company) {
                        let company = _.find(data.companies, {id: data.current_company});
                        this[SWITCH_TO_ACCOUNT](company);
                        return this.$router.push({name: 'dashboard'});
                    }
                    // go to first company
                    if (data.companies.length) {
                        let company = _.first(data.companies);
                        this[SWITCH_TO_ACCOUNT](company);
                        return this.$router.push({name: 'dashboard'});
                    }
                }).catch(err => {
                    this.$toast.error("Cannot register with social.");
                })
            },

            validateForm() {
                this.errors.first_name = "";
                this.errors.last_name = "";
                this.errors.email = "";
                this.errors.password = "";
                this.errors.password_confirmation = "";

                if (_.isEmpty(this.form.first_name)) {
                    this.errors.first_name = "The first name field is required.";
                    return false;
                }

                if (this.form.first_name.length > 50) {
                    this.errors.first_name = "The first name may not be greater than 50 characters.";
                    return false;
                }

                if (_.isEmpty(this.form.last_name)) {
                    this.errors.last_name = "The last name field is required.";
                    return false;
                }

                if (this.form.first_name.length > 50) {
                    this.errors.last_name = "The last name may not be greater than 50 characters.";
                    return false;
                }


                if (this.form.email.length < 1 || !_helper.validateEmail(this.form.email)) {
                    this.errors.email = "Please type a valid email address";
                    return false;
                }

                if (this.form.password.length < 8) {
                    this.errors.password = "Password should be at least 8 characters long";
                    return false;
                }

                if (this.form.password_confirmation.length < 8) {
                    this.errors.password_confirmation = "Confirm password should be at least 8 characters long";
                    return false;
                }

                if (this.form.password !== this.form.password_confirmation) {
                    this.errors.password_confirmation = "Passwords did not match";
                    return false;
                }

                return true;
            }
        },
        mounted() {
            this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            _helper.setCookie('time_zone', this.timeZone, 1);
        }
    }
</script>


